import { createGlobalStyle, ThemeProps, css } from 'styled-components'
import theme from './theme'

export const menuTooltips = css`
  .ant-menu-inline-collapsed-tooltip {
    .ant-toolitp-content {
      font-weight: 500 !important;
    }

    .ant-tooltip-arrow-content {
      background-color: ${p => p.theme.menuInlineTooltip.bg} !important;
    }

    .ant-tooltip-inner {
      background: ${p => p.theme.menuInlineTooltip.bg} !important;
      border: 1px solid ${p => p.theme.menuInlineTooltip.bg} !important;
      > a {
        color: ${p => p.theme.menuInlineTooltip.color};
        font-weight: normal;

        &:hover {
          color: ${p => p.theme.menuInlineTooltip.active};
        }
      }
    }
  }
`

export const textUtils = css`
  .text-largest {
    font-size: 18px !important;
  }

  .text-larger {
    font-size: 16px !important;
  }

  .text-default {
    font-size: 14px !important;
  }

  .text-smaller {
    font-size: 13px;
  }

  .text-sm {
    font-size: 12px !important;
  }

  .text-xs {
    font-size: 10px;
  }

  .text-green {
    color: ${p => p.theme.green};
  }
`

const GlobalStyles = createGlobalStyle<ThemeProps<typeof theme>>`
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Meiryo', 'Segoe UI', 'PingFang SC',
      'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-variant: tabular-nums;
    line-height: 1.5;
    font-feature-settings: "tnum","tnum";
    -webkit-font-feature-settings: "tnum";
    -webkit-font-smoothing: subpixel-antialiased;
    background-color: ${p => p.theme.background};


    // disable styles
    *:not(.ant-pagination-item-link, .ant-btn):disabled ,
    .ant-radio-wrapper:has(.ant-radio-disabled) span,
    .ant-checkbox-disabled + span,
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    .ant-select-disabled .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item
    {
      color:  ${p => p.theme.disable} !important;

      .ant-select-multiple .ant-select-selection-item-content{
        color:  ${p => p.theme.disable} !important;
      }

    }



  }

  /* ant-design overrides */

  .ant-message {
    top: 64px;
  }

  .ant-modal {
    animation-duration: 0s !important;
    transition: none !important;
  }

  .ant-spin-dot-item {
    background: ${p => p.theme.primary} !important;
  }

  .ant-spin-text {
    color: ${p => p.theme.yellow} !important;
  }

  .ant-image-preview-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* google maps overrides */
  .gm-marker-alarm::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #ff342b;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    right: 3px;
    top: 1px;
  }

  .gm-marker-normal::after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: #6dd400;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    right: 3px;
    top: 1px;
  }

  .gm-style .style-info-box  {
    width: auto !important;

    > img:first-child{
      display:none;
    }
  }

  .gm-style-cc {
    display: none;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    div:nth-child(2) {
      display: none;
    }
    .gm-control-active {
      width: 28px !important;
      height: 28px !important;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f4f3f3 !important;
      img {
        display: none !important;
      }
      &:first-child:after {
        content: '+';
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        font-size: 32px;
        color: #003248;
        line-height: 0;
      }
      &:last-child {
        &:after {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 17px;
          height: 1px;
          border-top: solid 3px #003248;
          text-align: center;
        }
      }
    }
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: unset;
  }

  .gm-ui-hover-effect {
    visibility: hidden !important;
  }

  .gm-style a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gm-style .gm-style-iw-d {
    overflow: unset !important;
  }

  .gm-style .gm-style-iw-c {
    padding-top: 32px;
    padding-left: 0 !important;
  }

  .gm-style .gm-style-iw > button > img {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    right: 10px;
    top: 5px;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gm-control-active:first-child:after {
    width: 18px;
    height: 7px !important;
  }

  .gm-style .gm-style-iw-t::after {
    display: none;
  }

  .gm-bundled-control {
    margin: 0 !important;
    bottom: 100px !important;
    left: 24px !important;

    > .gmnoprint > div {
      height: auto !important;
      background: transparent !important;
      box-shadow: none !important;
      cursor: default !important;
    }

    .gm-control-active {
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f4f3f3 !important;
    }

    .gm-control-active:first-child + div {
      display: none;
    }

    .gm-control-active:last-child {
      top: 12px !important;
    }
  }

  .cluster__icon {
    img {
      width: 40px;
      height: 40px;
    }
  }

  /* customize styles */
  .page__search-form {
    .ant-form-item-label {
      padding: 0 0 2px;
    }
  }

  .modal__form .flex:nth-child(even) div[class*=Column] {
      background: ${p => p.theme.block.content};
    }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .anticon.c-icon {
    &.icon-minus-circle svg {
      fill: ${p => p.theme.icon.primary};

      &:hover {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }
  }

  .anticon:hover {
    svg {
      fill: ${p => p.theme.icon.primaryHover};
    }
  }

  svg.svg-icon  {
    cursor:pointer;
    display:inline-block;

    &.is--small,
    &.icon-loading {
        height: 16px;
        width: 16px;
    }

    &.icon-add,
    &.icon-eye,
    &.icon-process,
    &.icon-info {
      > g path:nth-child(2),
      > g path:nth-child(2),
      use {
        fill: ${p => p.theme.icon.primary};
      }
    }

    &.icon-play:hover {
      > g {
        opacity: 1;
      }
      > g > g > g > g > g > g > g > circle {
        stroke: ${p => p.theme.icon.primaryHover};
      }
      > g > g > g > g > g > g > g > g > g path {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-location:hover,
    &.icon-process:hover {
      > g > path:nth-child(2) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-eye:hover {
      use {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-edit:hover,
    &.icon-minus-circle:hover  {
      g g {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-info:hover {
      > g path:nth-child(3) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.is--primary {
      &.icon-add > g path:nth-child(2) {
        fill: ${p => p.theme.primary};
      }
    }

    &.is--secondary {
      svg {
        height: 16px;
        width: 16px;
      }

      &.icon-add > g path:nth-child(2),
      &.icon-export > g path:nth-child(2),
      &.icon-eye use {
        fill: ${p => p.theme.icon.secondary};
      }

      g path:nth-child(3) {
        fill: ${p => p.theme.icon.secondary};
      }
    }
  }

  .c-link {
    color: #00a0e8;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #00a0e8;
    }
  }

  .c-btn.ant-btn {
    border-radius: 3px;
    font-weight: 500;
  }

  .c-btn--primary.ant-btn {
    color: ${p => p.theme.btn.text};
    border-color: ${p => p.theme.btn.bg};
    background: ${p => p.theme.btn.bg};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.text};
      background: ${p => p.theme.btn.bgHover};
      border-color: ${p => p.theme.btn.bgHover};
    }
  }

  .c-btn--outline.ant-btn {
    color: ${p => p.theme.btn.bg};
    border: 1.3px solid ${p => p.theme.btn.bg};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.bg};
      background: ${p => p.theme.btn.outlineHover};
      border: 1.3px solid ${p => p.theme.btn.bg};
    }
  }

  .c-btn--danger.ant-btn {
    color: ${p => p.theme.danger};
    border: 1.3px solid ${p => p.theme.danger};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.danger};
      background: ${p => p.theme.lightPink};
      border: 1.3px solid ${p => p.theme.danger};
    }
  }

  &.c-btn--link {
    background: #fff;
    border: transparent;
  }

  .sks-small-select  .ant-select-selector{
     height: 24px !important;

      .ant-select-selection-item, .ant-select-selection-placeholder {
        line-height: 1.5  !important;
      }
  }

  .sks-small-select   .ant-select-clear {
    top: 45%!important;
  }

  .bg-lightYellow {
    background: #fffcf1 !important;
  }

  tr.gl-row-dragging {
    background: #fffcf1;
    height: 48px;
    display:flex;
    align-items: center;

    td.drag-visible {
      padding-right: 16px;
      padding-left: 16px;
      visibility: visible;

      &.flex-auto {
        flex:1;
        text-align: left;
      }
    }
  }



  ${textUtils}
  ${menuTooltips}
`

export default GlobalStyles
